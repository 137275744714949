import {getComponentConfig, isSingleEventWidget} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {EventImage as Presentation} from './event-image'
import {EventImageOwnProps, EventImageStateProps} from './interfaces'

const mapState = ({state}: AppProps) => ({
  singleLayout: isSingleEventWidget(getComponentConfig(state)),
})

export const EventImage = connect<EventImageOwnProps, EventImageStateProps>(mapState)(Presentation)
